<template>
  <v-container class="fill-height">
    <div>
      <h1>Ошибка доступа</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Page403",
  metaInfo: {
    title: "Доступ запрещен",
  },
};
</script>

<style></style>
